import { PreloadedQuery, usePreloadedQuery } from "react-relay";
import { graphql } from "relay-hooks";
import { ClubScreenQueryRendererQuery } from "./__generated__/ClubScreenQueryRendererQuery.graphql";
import { useSetAtom } from "jotai";
import { userProfileStateAtom } from "../../../features/Profile/atoms";
import { useEffect } from "react";
import { iProfile } from "../../../features/Profile";
import { NavigationProp, useNavigation } from "@react-navigation/native";
import { ClubNavigatorParamList } from "../../../navigators/ClubNavigator";
import { brandShadowSm, customColorTokens } from "../../../../tamagui.config";

import { View, ScrollView, Text, XStack, YStack, Button } from "tamagui";

import { Header } from "../../../components/design-system/Header";

import { Image, Linking } from "react-native";

import { ClubLogo } from "../../../components/ClubLogo";
import { PartnersList } from "../features/PartnersList/PartnersList";
import { ArrowRightIcon } from "../../../components/design-system/icons/ArrowRightIcon";

type ClubScreenQueryRendererProps = {
  queryRef: PreloadedQuery<ClubScreenQueryRendererQuery>;
};

export const ClubScreenQueryRenderer = ({
  queryRef,
}: ClubScreenQueryRendererProps) => {
  const data = usePreloadedQuery<ClubScreenQueryRendererQuery>(
    ClubScreenQueryString,
    queryRef
  );
  const profile = data.profilesCollection?.edges?.[0]?.node;
  const setProfileState = useSetAtom(userProfileStateAtom);

  useEffect(() => {
    setProfileState({ ...profile } as iProfile);
  }, [profile, setProfileState]);

  const navigation = useNavigation<NavigationProp<ClubNavigatorParamList>>();

  const userRole = profile?.user_rolesCollection?.edges?.[0]?.node?.role;

  console.log("userRole", userRole);

  return (
    <View
      flex={1}
      style={{ flex: 1, backgroundColor: customColorTokens.brandYellow }}
    >
      <Header title="Membership" />

      <ScrollView
        flex={1}
        bg={customColorTokens.brandParchment}
        contentContainerStyle={{
          backgroundColor: customColorTokens.brandParchment,
          paddingBottom: 100,
        }}
        showsVerticalScrollIndicator={false}
      >
        <View flex={1}>
          <ScrollView pt="$4">
            <YStack
              flex={1}
              $gtMd={{
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <View flex={1} w="100%" bg="$brandParchment" p="$4">
                <Button
                  p={0}
                  pressStyle={{
                    scaleX: 0.99,
                    scaleY: 0.99,
                    bg: "white",
                    shadowOffset: { height: 1, width: 1 }, // Reduced shadow offset for pressed effect
                    shadowOpacity: 0.7, // Lower opacity for a softer shadow when pressed
                    // Keep the shadow radius unchanged or adjust slightly if needed
                  }}
                  h={120}
                  {...{
                    shadowColor: "black",
                    shadowOffset: { width: 2, height: 2 },
                    shadowOpacity: 0.9,
                    shadowRadius: 0,
                  }}
                  onPress={() => navigation.navigate("Profile")}
                >
                  <View pos="relative" overflow="hidden" w="100%" h="100%">
                    <Image
                      style={{
                        flex: 1,
                        width: "100%",
                        height: "100%",
                        position: "absolute",
                        zIndex: -1,
                        borderRadius: 8,
                        objectFit: "cover",
                      }}
                      source={require("../../../../assets/pattern.png")}
                    />

                    <XStack
                      flex={1}
                      justifyContent="center"
                      alignItems="center"
                      padding="$4"
                      width={"100%"}
                    >
                      <ClubLogo width={200} height={100} color="black" />
                      <View pos="absolute" right={16}>
                        <ArrowRightIcon width={20} height={20} />
                      </View>
                    </XStack>
                  </View>
                </Button>
              </View>

              <YStack
                gap="$4"
                mt="$4"
                py="$2"
                position="relative"
                flex={1}
                bg="$brandParchment"
              >
                {userRole && userRole !== "Member" ? (
                  <YStack gap="$6" px="$4">
                    <Text
                      fontSize={16}
                      textTransform="uppercase"
                      fontFamily="$heading"
                    >
                      Partners & Perks
                    </Text>

                    <PartnersList fragmentRef={data} />
                  </YStack>
                ) : (
                  <YStack
                    flex={1}
                    bg="$brandParchment"
                    alignItems="center"
                    justifyContent="center"
                    gap="$4"
                  >
                    <Text fontSize={20}>Want to become a paid member?</Text>
                    <Button
                      bg="$brandYellow"
                      {...brandShadowSm}
                      borderWidth={1}
                      borderColor="black"
                      onPress={() =>
                        Linking.openURL("https://breakfastclubatx.com/club")
                      }
                      fontFamily="$heading"
                      fontSize={16}
                    >
                      JOIN THE CLUB
                    </Button>
                  </YStack>
                )}
              </YStack>
            </YStack>
          </ScrollView>
        </View>
      </ScrollView>
    </View>
  );
};

export const ClubScreenQueryString = graphql`
  query ClubScreenQueryRendererQuery($id: UUID!) {
    profilesCollection(filter: { id: { eq: $id } }, first: 1) {
      edges {
        node {
          ...ProfileCard_fragment
          user_rolesCollection {
            edges {
              node {
                role
              }
            }
          }
        }
      }
    }
    ...PartnersList_fragment
  }
`;
