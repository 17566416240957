import { createAnimations } from "@tamagui/animations-react-native";

import { createMedia } from "@tamagui/react-native-media-driver";

import { shorthands } from "@tamagui/shorthands";

import { themes, tokens } from "@tamagui/themes";

import { createFont, createTamagui, createTokens } from "tamagui";

export const customColorTokens = {
  ...tokens.color,
  brandYellow: "#e1b225",
  brandParchment: "#fffef6",
};

const myTokens = createTokens({
  ...tokens,
  color: customColorTokens,
});

export const brandShadow = {
  shadowColor: "black",
  shadowOffset: { width: 2, height: 2 },
  shadowOpacity: 0.9,
  shadowRadius: 0,
  elevation: 1,
};

export const brandShadowSm = {
  shadowColor: "black",
  shadowOffset: { width: 1.5, height: 1.5 },
  shadowOpacity: 0.9,
  shadowRadius: 0,
  elevation: 1,
};

const animations = createAnimations({
  bouncy: {
    type: "spring",

    damping: 10,

    mass: 0.9,

    stiffness: 100,
  },

  subtle: {
    type: "spring",
    damping: 20,
    mass: 1.1,
    stiffness: 400,
  },

  lazy: {
    type: "spring",

    damping: 20,

    stiffness: 60,
  },

  quick: {
    type: "spring",

    damping: 20,

    mass: 1.2,

    stiffness: 250,
  },
});

const headingFont = createFont({
  family: "InterBlack",
  size: {
    1: 40,
    2: 32,
    3: 24,
    4: 20,
    5: 18,
    true: 40,
  },
});

const subheadingFont = createFont({
  family: "InterBold",
  size: {
    1: 32,
    2: 24,
    3: 20,
    4: 16,
    5: 14,
    true: 20,
  },
});

const bodyFont = createFont({
  family: "Souvenir",
  size: {
    1: 24,
    2: 20,
    3: 16,
    4: 12,
    5: 10,
    true: 16,
  },
});

const italicHeadingFont = createFont({
  family: "InterBlackItalic",
  size: {
    1: 40,
    2: 32,
    3: 24,
    4: 20,
    5: 18,
    true: 40,
  },
});

const customThemes = {
  ...themes,
  light: {
    ...themes.light,
    background: customColorTokens.brandParchment,
    color: "black",
  },
  dark: {
    ...themes.dark,
    background: customColorTokens.brandParchment,
    color: "black",
  },
};

const config = createTamagui({
  animations,

  defaultTheme: "light",

  shouldAddPrefersColorThemes: true,

  themeClassNameOnRoot: true,

  shorthands,

  fonts: {
    heading: headingFont,
    subHeading: subheadingFont,
    body: bodyFont,
    italicHeadingFont,
  },
  defaultFont: "body",
  themes: customThemes,

  tokens: myTokens,

  media: createMedia({
    xs: { maxWidth: 660 },

    sm: { maxWidth: 800 },

    md: { maxWidth: 1020 },

    lg: { maxWidth: 1280 },

    xl: { maxWidth: 1420 },

    xxl: { maxWidth: 1600 },

    gtXs: { minWidth: 660 + 1 },

    gtSm: { minWidth: 800 + 1 },

    gtMd: { minWidth: 1020 + 1 },

    gtLg: { minWidth: 1280 + 1 },

    short: { maxHeight: 820 },

    tall: { minHeight: 820 },

    hoverNone: { hover: "none" },

    pointerCoarse: { pointer: "coarse" },
  }),
});

export type AppConfig = typeof config;
declare module "tamagui" {
  // overrides TamaguiCustomConfig so your custom types

  // work everywhere you import `tamagui`

  interface TamaguiCustomConfig extends AppConfig {}
}
export default config;
