import * as React from "react";
import { MotiView } from "moti";
import { View } from "tamagui";
import { Easing } from "react-native-reanimated";

type Props = {
  fadeInDelay?: number;
};

const SpinnerComponent = ({ fadeInDelay = 1000 }: Props) => {
  return (
    <View alignItems="center" justifyContent="center">
      <MotiView
        from={{
          scale: 0.8,
        }}
        animate={{
          scale: 1.2,
        }}
        transition={{
          duration: 500,
          rubberBandEffect: {
            damping: 10,
            stiffness: 100,
          },
          easing: Easing.inOut(Easing.ease),
          loop: true,
        }}
        style={{
          width: 20,
          height: 20,
          borderRadius: 10,
          backgroundColor: "#000",
        }}
      />
    </View>
  );
};

SpinnerComponent.displayName = "Spinner";

export const Spinner = React.memo(SpinnerComponent);
