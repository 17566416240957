import { Stack, Text, View, XStack } from "tamagui";
import { Button } from "../../../../components/design-system";
import { Platform, Image } from "react-native";
import * as Haptics from "expo-haptics";
import mixpanel from "../../../../../mixpanel";
import { NavigationProp, useNavigation } from "@react-navigation/native";
import { ClubNavigatorParamList } from "../../../../navigators/ClubNavigator";
import { graphql, useFragment } from "relay-hooks";
import { PartnersList_fragment$key } from "../../Profile/components/__generated__/PartnersList_fragment.graphql";
import { supabase } from "../../../../../lib/supabase";
import { currentUserAtom } from "../../../../../atoms/global";
import { useAtomValue } from "jotai";

type PartnersListProps = {
  fragmentRef: PartnersList_fragment$key;
};

export const PartnersList = ({ fragmentRef }: PartnersListProps) => {
  const user = useAtomValue(currentUserAtom);
  const data = useFragment(PartnersListFragment, fragmentRef);

  const partners = data.partnersCollection?.edges || [];

  const navigation = useNavigation<NavigationProp<ClubNavigatorParamList>>();

  const onPartnerPress = (partnerId: string, name: string) => {
    if (Platform.OS === "ios") Haptics.selectionAsync();

    mixpanel.track("Partner Pressed", { partner: name });

    navigation.navigate("Partner", { partnerId });
  };

  return (
    <Stack
      flexDirection={"row"}
      flexWrap={"wrap"}
      justifyContent="space-between"
      gap="$2"
      columnGap="$2"
    >
      {partners.map((item) => {
        {
          const imageUrl = item.node.image_url
            ? supabase.storage
                .from("partners")
                .getPublicUrl(item.node.image_url)?.data?.publicUrl
            : null;

          return (
            <View w={"48%"}>
              <Button
                key={item.node.id}
                bg="white"
                p={0}
                mb="$2"
                pressStyle={{
                  scaleX: 0.99,
                  scaleY: 0.99,
                  bg: "white",
                  shadowOffset: { height: 1, width: 1 }, // Reduced shadow offset for pressed effect
                  shadowOpacity: 0.7, // Lower opacity for a softer shadow when pressed
                  // Keep the shadow radius unchanged or adjust slightly if needed
                }}
                {...{
                  shadowColor: "black",
                  shadowOffset: { width: 2, height: 2 },
                  shadowOpacity: 0.9,
                  shadowRadius: 0,
                }}
                onPress={() =>
                  onPartnerPress(item.node.id, item.node.name ?? "")
                }
              >
                <XStack
                  p="$4"
                  key={item.node.id}
                  justifyContent="center"
                  alignItems="center"
                >
                  {imageUrl ? (
                    <Image
                      style={{
                        width: "100%",
                        objectFit: "contain",
                        minHeight: 100,
                      }}
                      source={{ uri: imageUrl }}
                      resizeMode="contain"
                    />
                  ) : (
                    <Text
                      fontFamily="$heading"
                      fontSize={16}
                      textAlign="center"
                    >
                      {item.node.name}
                    </Text>
                  )}
                </XStack>
              </Button>
            </View>
          );
        }
      })}
    </Stack>
  );
};

export const PartnersListFragment = graphql`
  fragment PartnersList_fragment on Query {
    partnersCollection(
      orderBy: { index: AscNullsLast }
      filter: { enabled: { eq: true } }
    ) {
      edges {
        node {
          id
          name
          image_url
        }
      }
    }
  }
`;
